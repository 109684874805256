<template>
  <div>
    <div class="mrb20 bg-white pd20">
      <div class="mrb20">
        <el-button icon="el-icon-plus" type="primary" @click="updatepassword()">新增账号</el-button>
      </div>
      <div>
        <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" style="width: 100%">
          <el-table-column label="ID" prop="user.id" width="50"></el-table-column>

          <el-table-column label="用户名称" prop="user.name">
          </el-table-column>

          <el-table-column label="登录账号" prop="user.username">
          </el-table-column>
          <el-table-column label="角色名称" prop="role_name">
          </el-table-column>
          <el-table-column label="账号备注" prop="notes">
          </el-table-column>
          <el-table-column label="使用状态" prop="status_name">
          </el-table-column>
          <!-- <el-table-column prop="address" label="头像">
            <template slot-scope="scope">
              <el-image :preview-src-list="[scope.row.user.avatar]" style="width: 100px; height: 100px" :src="scope.row.user.avatar" fit="fill"></el-image>
            </template>
          </el-table-column> -->

          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="updatepassword(scope.row)">修改密码</el-button>
              <el-button size="mini" type="danger" @click="del(scope.row.shop_user_id)">删除管理员</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="mrt20 flex justify-end">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pageInfo.page" :page-sizes="[10, 20, 50]" :page-size="pageInfo.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageInfo.count">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 新增管理员 -->
    <el-dialog title="新增管理员" :visible.sync="originalVisible" width="40%">
      <el-form ref="originaForm" :model="originaForm" label-width="100px">

        <el-form-item required>
          <template slot="label">
            <span>登录名
              <el-tooltip class="item" effect="dark" placement="right">
                <i class="el-icon-question" style="font-size: 16px; vertical-align: middle;"></i>
                <div slot="content">
                  <p>请输入(英文/数字组合)</p>
                </div>
              </el-tooltip>
            </span>
          </template>
          <el-input v-model="originaForm.username"></el-input>
        </el-form-item>

        <el-form-item required>
          <template slot="label">
            <span>昵称
              <el-tooltip class="item" effect="dark" placement="right">
                <i class="el-icon-question" style="font-size: 16px; vertical-align: middle;"></i>
                <div slot="content">
                  <p>请输入用户的(中文名称)</p>
                </div>
              </el-tooltip>
            </span>
          </template>
          <el-input v-model="originaForm.name"></el-input>
        </el-form-item>

        <el-form-item label="登录密码" required>
          <el-input v-model="originaForm.password"></el-input>
        </el-form-item>

        <el-form-item label="再次密码" required>
          <el-input v-model="originaForm.password1"></el-input>
        </el-form-item>
        <el-form-item label="角色配置">
          <el-select v-model="originaForm.role_id" placeholder="请选择角色列表" clearable>
            <el-option v-for="(item,i) in roleData" :key="i" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" required>
          <el-select v-model="originaForm.status" placeholder="请选择状态" clearable>
            <el-option v-for="item in  statusDetail" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="originaForm.notes"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="originalVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">{{ dialogTitle }}</el-button>
      </span>
    </el-dialog>

    <!-- 修改密码
    <el-dialog title="修改密码" :visible.sync="originalUpdateVisible" width="40%">
      <el-form ref="updateForm" :model="updateForm" label-width="100px">

        <el-form-item label="登录密码" required>
          <el-input v-model="updateForm.password"></el-input>
        </el-form-item>

        <el-form-item label="再次密码" required>
          <el-input v-model="updateForm.password1"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="originalUpdateVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmitUpdate()">修改密码</el-button>
      </span>
    </el-dialog> -->

  </div>
</template>

<script>
import { statusDetail } from "@/utils/statusData";

export default {
  components: {},
  props: {},
  data() {
    return {
      statusDetail,
      pageInfo: {
        limit: 10,
        page: 1,
        count: 0,
        pageNum: 0,
      },
      tableData: [],
      roleData: [],
      originalVisible: false,
      dialogTitle: "",
      // 新增
      originaForm: {
        name: "",
        username: "",
        password: "",
        password1: "",
        status: "",
        notes: "",
        role_id: "",
      },
      editID: "",
      updateForm: {
        shop_user_id: "",
        password: "",
        password1: "",
      },
      originalUpdateVisible: false,
      ifMaterialEdit: 0, // 0表示编辑，1表示新增
    };
  },

  created() {
    this.getList(this.pageInfo);
    this.getroleList();
  },
  mounted() {},
  computed: {},

  methods: {
    // 新增
    updatepassword(row) {
      if (row) {
        console.log("点击编辑", row);
        this.ifMaterialEdit = 0;
        this.originalVisible = true;
        this.editID = row.id;
        console.log(" 编辑提交的 this.editID", this.editID);
        this.dialogTitle = "编辑";
        this.originaForm = { ...row, ...row.user };
      } else {
        // console.log("新增咯");
        this.ifMaterialEdit = 1;
        this.originalVisible = true;
        this.dialogTitle = "新增";
        this.originaForm = {
          name: "",
          username: "",
          password: "",
          password1: "",
          status: "",
          notes: "",
          role_id: "",
        };
      }
    },
    detail(id) {
      this.$router.push({
        path: "/order/orderDetail?id=" + id,
      });
    },
    async getroleList() {
      const { data } = await this.$Api.permissionsRole();
      this.roleData = data.list;
      console.log("this.roleData", this.roleData);
    },

    handleSizeChange(val) {
      console.log("val", val);
      this.pageInfo.limit = val;
      this.pageInfo.page = 1;
      this.getList(this.pageInfo);
    },
    handleCurrentChange(val) {
      console.log("val", val);
      this.pageInfo.page = val;
      this.getList(this.pageInfo);
    },
    async getList(pageInfo) {
      const { data } = await this.$Api.shopshopUserList(pageInfo);
      this.tableData = data.list;
      console.log("成员列表", data);
      this.pageInfo.limit = Number(data.limit);
      this.pageInfo.page = Number(data.page);
      this.pageInfo.count = Number(data.count);
      this.pageInfo.pageNum = Number(data.pageNum);
    },
    async onSubmit() {
      // 新增接口对接

      // 新增接口对接
      if (this.ifMaterialEdit == 1) {
        // console.log("新增管理", this.originaForm);
        const data = await this.$Api.shopAddShopUser(this.originaForm);
        if (data.code == 200) {
          this.$message.success(data.msg);
          this.originalVisible = false;
        } else {
          this.$message.error(data.msg);
        }
        this.getList(this.pageInfo);
      } else if (this.ifMaterialEdit == 0) {
        // 编辑接口对接
        // console.log("this.originaForm9878979", this.originaForm);
        if (this.originaForm.user !== {}) {
          delete this.originaForm.user;
        }
        if (this.originaForm.id !== "") {
          delete this.originaForm.id;
        }
        console.log(this.roleForm);
        const data = await this.$Api.shopAddShopUser({
          id: this.editID,
          ...this.originaForm,
        });
        if (data.code == 200) {
          this.$message.success(data.msg);
          this.originalVisible = false;
        } else {
          this.$message.error(data.msg);
        }
        this.getList(this.pageInfo);
      }
    },

    async onSubmitUpdate() {
      // 修改密码对接
      const data = await this.$Api.shopUserUpdatePassword(this.updateForm);
      if (data.code == 200) {
        this.$message.success(data.msg);
        this.originalUpdateVisible = false;
      } else {
        this.$message.error(data.msg);
      }

      this.getList(this.pageInfo);
    },
    async del(shop_user_id) {
      const data = await this.$Api.shopUserDel({
        shop_user_id: shop_user_id,
      });

      if (data.code == 200) {
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }

      this.getList(this.pageInfo);
    },
  },
};
</script>
<style lang='scss' scoped>
.edit_box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
}
</style>