<template>
  <div>
    <div class="mrb20">共{{this.pageInfo.count}}个角色</div>
    <el-button type="primary" @click="editVisible = true">新建角色</el-button>
    <div class="mrt20">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="id" label="ID">
        </el-table-column>
        <el-table-column prop="name" label="角色名称">
        </el-table-column>
        <el-table-column prop="admin_count" label="成员人数">
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <div class="flex align-center">
              <el-button @click="handleClick(scope.row)" type="text">设置权限</el-button>
              <div class="mrlr5">|</div>
              <el-button type="text" @click="addMember(scope.row)">添加成员</el-button>
              <div class="mrlr5">|</div>
              <el-button type="text" @click="edit(scope.row)">编辑</el-button>
              <div class="mrlr5">|</div>
              <el-button type="text" @click="deleteRoal(scope.row.id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="mrt20 flex justify-end">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pageInfo.page" :page-sizes="[10, 20, 50]" :page-size="pageInfo.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageInfo.count">
        </el-pagination>
      </div>
    </div>

    <!-- 新增编辑弹窗 -->
    <el-dialog :title='dialogTitle' :visible.sync="editVisible" width="40%">
      <el-form ref="form" :model="roleForm" label-width="80px">
        <el-form-item label="角色名称">
          <el-input v-model="roleForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title='添加成员' :visible.sync="memberVisible" width="40%">
      <el-form ref="form" :model="memberForm" label-width=" 100px">
        <el-form-item required>
          <template slot="label">
            <span>登录名
              <el-tooltip class="item" effect="dark" placement="right">
                <i class="el-icon-question" style="font-size: 16px; vertical-align: middle;"></i>
                <div slot="content">
                  <p>请输入(英文/数字组合)</p>
                </div>
              </el-tooltip>
            </span>
          </template>
          <el-input v-model="memberForm.username"></el-input>
        </el-form-item>

        <el-form-item required>
          <template slot="label">
            <span>昵称
              <el-tooltip class="item" effect="dark" placement="right">
                <i class="el-icon-question" style="font-size: 16px; vertical-align: middle;"></i>
                <div slot="content">
                  <p>请输入用户的(中文名称)</p>
                </div>
              </el-tooltip>
            </span>
          </template>
          <el-input v-model="memberForm.name"></el-input>
        </el-form-item>
        <el-form-item label="密码" required>
          <el-input v-model="memberForm.password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" required>
          <el-input v-model="memberForm.password1"></el-input>
        </el-form-item>
        <!-- <el-form-item label="角色列表">
          <el-select v-model="memberForm.role_id" placeholder="请选择角色列表" @visible-change="getList()" clearable>
            <el-option v-for="(item,i) in tableData" :key="i" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="状态" required>
          <el-select v-model="memberForm.status" placeholder="请选择状态" clearable>
            <el-option v-for="item in  statusDetail" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="memberForm.notes"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="memberVisible = false">取 消</el-button>
        <el-button type="primary" @click="memberOnSubmit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { statusDetail } from "@/utils/statusData";

export default {
  components: {},
  props: {},
  data() {
    return {
      statusDetail,
      editVisible: false,
      memberVisible: false,
      ifMaterialEdit: 0, // 0表示编辑，1表示新增
      dialogTitle: "",
      roleForm: {
        name: "",
      },
      memberForm: {
        name: "",
        status: "",
        username: "",
        password: "",
        password1: "",
        role_id: "",
        notes: "",
      },
      pageInfo: {
        limit: 10,
        page: 1,
        count: 0,
        pageNum: 0,
      },
      tableData: [],
    };
  },

  created() {
    this.getList(this.pageInfo);
  },
  mounted() {},
  computed: {},

  methods: {
    // 新增编辑
    edit(row) {
      if (row) {
        console.log("点击编辑角色", row);
        this.ifMaterialEdit = 0;
        this.roleForm.id = row.id;
        this.editVisible = true;
        this.dialogTitle = "编辑";
        this.roleForm = { ...row };
      } else {
        // console.log("新增咯");
        this.ifMaterialEdit = 1;
        this.editVisible = true;
        this.dialogTitle = "新增";
        this.roleForm = {
          name: "",
        };
      }
    },
    async onSubmit(row) {
      // 新增接口对接
      if (this.ifMaterialEdit == 1) {
        // console.log("新增管理", this.roleForm);
        const data = await this.$Api.permissionsRoleEdit(this.roleForm);
        if (data.code !== 200) {
          this.$message.error(data.msg);
          return;
        }
        this.$message.success("新增成功");

        this.editVisible = false;
        this.getList(this.pageInfo);
      } else if (this.ifMaterialEdit == 0) {
        // 编辑接口对接

        const data = await this.$Api.permissionsRoleEdit(this.roleForm);
        this.$message.success("编辑成功");
        this.editVisible = false;
        this.getList(this.pageInfo);
      }
    },
    // 添加成员
    addMember(row) {
      console.log("拿到编辑的id", row);
      this.memberForm.role_id = row.id;
      console.log("this.memberForm.role_id", this.memberForm.role_id);
      this.memberVisible = true;
    },
    async memberOnSubmit() {
      const data = await this.$Api.shopAddShopUser(this.memberForm);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("新增成功");
      this.memberForm = {
        id: "",
        name: "",
        status: "",
        username: "",
        password: "",
        password1: "",
        role_id: "",
        notes: "",
      };
      this.memberVisible = false;
      this.getList(this.pageInfo);
    },
    handleClick(row) {
      this.$router.push({
        path: "/person/jurisdiction",
        query: { id: row.id },
      });
    },
    async getList(pageInfo) {
      const { data } = await this.$Api.permissionsRole(pageInfo);
      this.tableData = data.list;
      console.log("角色列表", data);
      this.pageInfo.limit = Number(data.limit);
      this.pageInfo.page = Number(data.page);
      this.pageInfo.count = Number(data.count);
      this.pageInfo.pageNum = Number(data.pageNum);
      // Object.assign(this.pageInfo, data);
      // console.log(data, "成功的原著列表");
    },
    handleSizeChange(val) {
      // console.log("val", val);
      this.pageInfo.limit = val;
      this.pageInfo.page = 1;
      this.getList(this.pageInfo);
    },
    handleCurrentChange(val) {
      // console.log("val", val);
      this.pageInfo.page = val;
      this.getList(this.pageInfo);
    },
    deleteRoal(id) {
      console.log("删除的");
      console.log("删除的id", id);
      this.$confirm("确定要删除该商品信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$Api.permissionsRoleDel({ id: id });
          // console.log("删除成功", data);
          if (data.code != 200) {
            return this.$message({
              message: data.msg,
              type: "error",
            });
          }
          this.$message({
            message: data.msg,
            type: "success",
          });
          this.getList(this.pageInfo);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: data.msg,
          });
        });
    },
  },
};
</script>
<style lang='scss' scoped>
</style>