<template>
  <div>
    <div class="mrb20 bg-white pd20">
      <el-tabs v-model="activeName">
        <el-tab-pane label="账号管理" name="1"></el-tab-pane>
        <el-tab-pane label="角色管理" name="2"></el-tab-pane>
      </el-tabs>
    </div>

    <div class="edit_box  mrb20">
      <person-list v-show="activeName == '1'"></person-list>
      <role v-show="activeName == '2'"></role>
    </div>
  </div>
</template>

<script>
import personList from "./personList.vue";
import role from "./role.vue";

export default {
  components: { personList, role },
  props: {},
  data() {
    return {
      activeName: "1",
    };
  },

  created() {},
  mounted() {},
  computed: {},

  methods: {},
};
</script>
<style lang='scss' scoped>
.edit_box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
}
</style>